<template>
  <div>
    <b-carousel
      id="carousel-example-generic"
      controls
      indicators
      :interval="4000"
    >
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block w-100"
            :src="require('@/assets/images/product/slot/496_0.png')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block w-100"
            :src="require('@/assets/images/product/slot/590_0.png')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block w-100"
            :src="require('@/assets/images/product/slot/591_0.png')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block w-100"
            :src="require('@/assets/images/product/slot/599_0.png')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block w-100"
            :src="require('@/assets/images/product/slot/634_0.png')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
    }
  },
}
</script>

<style>
.carousel-control-prev-icon,.carousel-control-next-icon{
  width:60px !important;
  height:60px !important;
}
.carousel-indicators > li {
 height:10px;
 width:10px;
 border-radius: 50%;
}
</style>
